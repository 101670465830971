import React, { Component } from 'react';

import './footer.css';

class Footer extends Component {
    render() {
        return(
            <footer>
                <div>Copyright &copy; 2018 - taylorgonzalez.me | Website was built by Taylor Gonzalez</div>
            </footer>
        );
    }
}

export default Footer;